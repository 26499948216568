// import React, { Suspense, lazy } from "react";
// import "./App.css";
// import { Route, Routes, useNavigate } from "react-router-dom";
// import LoginTemplate from "./components/templates/LoginTemplate/LoginTemplate";
// import Template from "./components/templates/HomeTemplate/Template";
// import TableTemplate from "./components/templates/TableTemplate/TableTemplate";
// import Spinner from "./components/atoms/spinner/Spinner";

// // Lazy-loaded components
// const HomePage = lazy(() => import("./components/pages/HomePage/homePage"));
// const Login = lazy(() => import("./components/pages/LoginPage/Login"));
// const Redirect = lazy(() => import("./components/pages/404 -Page/redirect"));
// const CcrSubmit = lazy(() => import("./components/pages/Ccr-Submit/CcrSubmit"));
// const Summary = lazy(() => import("./components/pages/SummaryPage/Summary"));
// const PurchaceOrder = lazy(() =>
//   import("./components/pages/PurchaceOrder/PurchaceOrder")
// );
// const StoreKeyMaster = lazy(() =>
//   import("./components/pages/StoreKeyMaster/storeKeyMaster")
// );
// const ProductCatalog = lazy(() =>
//   import("./components/pages/ProductCatalog/productCatalog")
// );
// const FileUpload = lazy(() =>
//   import("./components/pages/FileUpload/FileUpload")
// );
// const UserAdministration = lazy(() =>
//   import("./components/pages/UserAdministration/userAdministration")
// );
// const CashSupplyExpenses = lazy(() =>
//   import("./components/pages/CashSupplyExpenses/cashSupplyExpenses")
// );
// const CpgSummary = lazy(() =>
//   import("./components/pages/CpgSummary/CpgSummary")
// );
// const BarcodeScanner = lazy(() =>
//   import("./components/pages/BarcodeScanner/BarcodeScanner")
// );
// const ScannedData = lazy(() =>
//   import("./components/pages/BarcodeScanner/ScannedData")
// );
// const MasterCpgEntry = lazy(() =>
//   import("./components/pages/MasterCpgEntry/masterCpgEntry")
// );
// const Dashboard = lazy(() => import("./components/pages/Dashboard/Dashboard"));
// const ErrorPage = lazy(() => import("./components/pages/ErrorPage/ErrorPage"));

// function App() {
//   const navigate = useNavigate();

//   return (
//     <div className="App">
//       <Suspense
//         fallback={
//           <div>
//             <Spinner />
//           </div>
//         }
//       >
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <LoginTemplate>
//                 <Login />
//               </LoginTemplate>
//             }
//           ></Route>

//           <Route
//             path="/homepage"
//             element={
//               <Template appname={"HOMEPAGE"}>
//                 <HomePage />
//               </Template>
//             }
//           />
//           <Route
//             path="/ccr-submit"
//             element={
//               <Template appname={"CLOSING COMMENTS APP"}>
//                 <CcrSubmit />
//               </Template>
//             }
//           />
//           <Route
//             path="/summary"
//             element={
//               <Template appname={"CLOSING REPORT WEEKLY SUMMARY "}>
//                 <Summary />
//               </Template>
//             }
//           />
//           <Route
//             path="/purchase-order"
//             element={
//               <TableTemplate appname={"PURCHASE ORDER"}>
//                 <PurchaceOrder />
//               </TableTemplate>
//             }
//           />

//           <Route
//             path="/storekeymaster"
//             element={
//               <TableTemplate appname={"STOREKEYMASTER"}>
//                 <StoreKeyMaster />
//               </TableTemplate>
//             }
//           />

//           <Route
//             path="/productcatalog"
//             element={
//               <TableTemplate appname={"PRODUCTCATALOG"}>
//                 <ProductCatalog />
//               </TableTemplate>
//             }
//           />

//           <Route
//             path="/fileupload"
//             element={
//               <TableTemplate appname={"FILE UPLOAD"}>
//                 <FileUpload />
//               </TableTemplate>
//             }
//           />

//           <Route
//             path="/adduser"
//             element={
//               <TableTemplate appname={"USER ADMINISTRATION"}>
//                 <UserAdministration />
//               </TableTemplate>
//             }
//           />

//           <Route
//             path="/master-cpg-entry"
//             element={
//               <TableTemplate appname={"Master CPG Entry"}>
//                 <MasterCpgEntry />
//               </TableTemplate>
//             }
//           />

//           <Route
//             path="/cash-supply-expenses"
//             element={
//               <TableTemplate appname={"CASH SUPPLY EXPENSES"}>
//                 <CashSupplyExpenses />
//               </TableTemplate>
//             }
//           />
//           <Route
//             path="/cash-supply-summary"
//             element={
//               <Template appname={"CASH SUPPLY SUMMARY"}>
//                 <CpgSummary />
//               </Template>
//             }
//           />

//           <Route
//             path="/barcodescanner"
//             element={
//               <Template appname={"BARCODE SCANNER"}>
//                 <BarcodeScanner />
//               </Template>
//             }
//           />
//           <Route
//             path="/dashboard"
//             element={
//               <Template appname={"DASHBOARD"}>
//                 <Dashboard />
//               </Template>
//             }
//           />

//           <Route path=":page/error" element={<ErrorPage />} />
//           <Route
//             path="*"
//             element={
//               <Template>
//                 <Redirect />
//               </Template>
//             }
//           />

//           <Route
//             path="/barcodetable"
//             element={
//               <TableTemplate appname={"BARCODE TABLE"}>
//                 <ScannedData />
//               </TableTemplate>
//             }
//           />
//         </Routes>
//       </Suspense>
//     </div>
//   );
// }

import React, { Suspense, lazy } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginTemplate from "./components/templates/LoginTemplate/LoginTemplate";
import Template from "./components/templates/HomeTemplate/Template";
import TableTemplate from "./components/templates/TableTemplate/TableTemplate";
import Spinner from "./components/atoms/spinner/Spinner";
import CacheBuster from "react-cache-buster"; // Import react-cache-buster
import packageJson from "../package.json"; // Adjust the path if needed

// Lazy-loaded components
const HomePage = lazy(() => import("./components/pages/HomePage/homePage"));
const Login = lazy(() => import("./components/pages/LoginPage/Login"));
const Redirect = lazy(() => import("./components/pages/404 -Page/redirect"));
const CcrSubmit = lazy(() => import("./components/pages/Ccr-Submit/CcrSubmit"));
const Summary = lazy(() => import("./components/pages/SummaryPage/Summary"));
const PurchaceOrder = lazy(() =>
  import("./components/pages/PurchaceOrder/PurchaceOrder")
);
const StoreKeyMaster = lazy(() =>
  import("./components/pages/StoreKeyMaster/storeKeyMaster")
);
const ProductCatalog = lazy(() =>
  import("./components/pages/ProductCatalog/productCatalog")
);
const FileUpload = lazy(() =>
  import("./components/pages/FileUpload/FileUpload")
);
const UserAdministration = lazy(() =>
  import("./components/pages/UserAdministration/userAdministration")
);
const CashSupplyExpenses = lazy(() =>
  import("./components/pages/CashSupplyExpenses/cashSupplyExpenses")
);
const CpgSummary = lazy(() =>
  import("./components/pages/CpgSummary/CpgSummary")
);
const BarcodeScanner = lazy(() =>
  import("./components/pages/BarcodeScanner/BarcodeScanner")
);
const ScannedData = lazy(() =>
  import("./components/pages/BarcodeScanner/ScannedData")
);
const MasterCpgEntry = lazy(() =>
  import("./components/pages/MasterCpgEntry/masterCpgEntry")
);
const Dashboard = lazy(() => import("./components/pages/Dashboard/Dashboard"));
const ErrorPage = lazy(() => import("./components/pages/ErrorPage/ErrorPage"));

// Use the version from package.json
const version = packageJson.version; // Get version dynamically
// const isProduction = process.env.NODE_ENV === "production";

function App() {
  const navigate = useNavigate();
  console.log("version", version);
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Spinner />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="App">
        <Suspense
          fallback={
            <div>
              <Spinner />
            </div>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <LoginTemplate>
                  <Login />
                </LoginTemplate>
              }
            ></Route>

            <Route
              path="/homepage"
              element={
                <Template appname={"HOMEPAGE"}>
                  <HomePage />
                </Template>
              }
            />
            <Route
              path="/ccr-submit"
              element={
                <Template appname={"CLOSING COMMENTS APP"}>
                  <CcrSubmit />
                </Template>
              }
            />
            <Route
              path="/summary"
              element={
                <Template appname={"CLOSING REPORT WEEKLY SUMMARY "}>
                  <Summary />
                </Template>
              }
            />
            <Route
              path="/purchase-order"
              element={
                <TableTemplate appname={"PURCHASE ORDER"}>
                  <PurchaceOrder />
                </TableTemplate>
              }
            />
            <Route
              path="/storekeymaster"
              element={
                <TableTemplate appname={"STOREKEYMASTER"}>
                  <StoreKeyMaster />
                </TableTemplate>
              }
            />
            <Route
              path="/productcatalog"
              element={
                <TableTemplate appname={"PRODUCTCATALOG"}>
                  <ProductCatalog />
                </TableTemplate>
              }
            />
            <Route
              path="/fileupload"
              element={
                <TableTemplate appname={"FILE UPLOAD"}>
                  <FileUpload />
                </TableTemplate>
              }
            />
            <Route
              path="/adduser"
              element={
                <TableTemplate appname={"USER ADMINISTRATION"}>
                  <UserAdministration />
                </TableTemplate>
              }
            />
            <Route
              path="/master-cpg-entry"
              element={
                <TableTemplate appname={"Master CPG Entry"}>
                  <MasterCpgEntry />
                </TableTemplate>
              }
            />
            <Route
              path="/cash-supply-expenses"
              element={
                <TableTemplate appname={"CASH SUPPLY EXPENSES"}>
                  <CashSupplyExpenses />
                </TableTemplate>
              }
            />
            <Route
              path="/cash-supply-summary"
              element={
                <Template appname={"CASH SUPPLY SUMMARY"}>
                  <CpgSummary />
                </Template>
              }
            />
            <Route
              path="/barcodescanner"
              element={
                <Template appname={"BARCODE SCANNER"}>
                  <BarcodeScanner />
                </Template>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Template appname={"DASHBOARD"}>
                  <Dashboard />
                </Template>
              }
            />
            <Route path=":page/error" element={<ErrorPage />} />
            <Route
              path="*"
              element={
                <Template>
                  <Redirect />
                </Template>
              }
            />
            <Route
              path="/barcodetable"
              element={
                <TableTemplate appname={"BARCODE TABLE"}>
                  <ScannedData />
                </TableTemplate>
              }
            />
          </Routes>
        </Suspense>
      </div>
    </CacheBuster>
  );
}

export default App;
